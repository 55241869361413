import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import "./technical-assistance.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGlobe,
  faEnvelope,
  faUser,
  faPhoneVolume,
  faSearch,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons"
import Parter from "../data/partners"
import { faAccessibleIcon } from "@fortawesome/free-brands-svg-icons"

const Partners = () => {
  const [partner, setPartner] = useState(Parter.partners)

  return (
    <Layout>
      <SEO title="About" />
      <div className="container primary-bg">
      <div>
          <label className="title">About BizHelp.Miami</label>
          <div className="TextIntro">
            BizHelp.Miami is a one-stop portal featuring information about and access to the local, state and federal resources small businesses need in order to mitigate the impact of the coronavirus. Powered by the Miami-Dade Beacon Council, the County's official economic development partner, the site features access to approved lenders, additional funding options ranging from loans to grants, and a vetted list of community partners that are ready, willing and able to help you better understand and successfully navigate the funding resources available to Miami-Dade small businesses, sole proprietors and independent contractors. 
          </div>
        </div>
        
        <div>
          <label className="title">Community Partners</label>
          <div className="TextIntro">
            Miami-Dade has long understood that we are at our best when we are
            working together to safeguard the prosperity and well-being of our
            community; that is what is at the core of One Community One Goal,
            our County’s collaborative roadmap to success. Dozens of partners
            quickly mobilized over the last few weeks to help us bring these
            resources to Miami-Dade’s small business community. Thank you for
            all that you do and have done. We appreciate your partnership and
            leadership as we work together to support our residents and the
            diverse, world-class business community they have worked diligently
            to build.
          </div>
        </div>
        <hr />
        <section className="container">
          {partner.sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((key, index) => {
              return (
                <div className="card">
                  <label className="additonalFundingCardTitle">
                    <a href={key.link} target="_blank">
                      {key.name}
                    </a>
                  </label>
                </div>
              )
            })}
        </section>
      </div>
    </Layout>
  )
}

export default Partners
